App = {

	/**
	 * Initialize application
	 *
	 * @return {void}
	 */
	init: function() {
		console.log("Initialized.");

		this.addEvents();
		this.resizeBoatListTags();
		this.initSliders();
		this.initToggles();
		this.initDates();
		this.initFilters();
		this.initLightboxes();

		$( window ).resize(function() { App.resize(); });
	},

	/**
	 * Init the slider component
	 *
	 * @return {void}
	 */
	initSliders: function() {
		$(".photo-slider").owlCarousel({
			singleItem: true,
			navigation: true,
			pagination: false,
			autoHeight: true,
			stopOnHover: true
		});
	},

	/**
	 * Init content toggling
	 *
	 * @return {void}
	 */
	initToggles: function() {
		$(".toggle").click(function() {
			$(this).toggleClass("active");
			$(this).parent().find(".togglable").toggleClass("active");
		});
	},

	/**
	 * Init datepicker components
	 *
	 * @return {void}
	 */
	initDates: function() {
		$(".datepicker").datepicker({
			autoclose: true
			// Additional options as required
		});
	},

	/**
	 * Resizes the tags for each list item
	 *
	 * @return {void}
	 */
	resizeBoatListTags: function() {
		$(".boat-list-item").each(function() {
			var $item = $(this);
			var $tag  = $item.find(".tag");

			if ($tag.length) {
				var itemWidth  = $item.width();
				var priceWidth = $item.find(".price").width();
				var tagWidth   = itemWidth - priceWidth - 40;

				$tag.width(tagWidth);
			}
		});
	},

	/**
	 * Initialize boat filter actions
	 *
	 * @return {void}
	 */
	initFilters: function() {
		// Toggle more options
		$(".btn-more-options").click(function() {
			$(".more-filters").toggle();
			return false;
		});

		// Range inputs/sliders
		$(".fancy-range").slider();
		$(".fancy-range-length").slider({
			formatter: function(value) {
				return value[0] + 'm - ' + value[1] + 'm';
			},
			tooltip: 'always'
		});
		$(".fancy-range-price").slider({
			formatter: function(value) {
				return '€ ' + value[0] + ' - € ' + value[1];
			},
			tooltip: 'always'
		});
	},

	/**
	 * Init the lightbox elements
	 *
	 * @return {void}
	 */
	initLightboxes: function() {
		var _this = this;

		if ( ! $("#lightbox-container").length) {
			this.$container = $('<div id="lightbox-container" style="position: fixed; right: 0px; left: 0px; bottom: 0px; top: 0px; z-index: 2147483600; width: 100%; display: block; height: 1122px; background: transparent; display: none;" />');
			this.$overlay = $('<div id="lightbox-overlay" style="position: absolute; right: 0px; left: 0px; bottom: 0px; top: 0px; z-index: 1; width: 100%; opacity: 0.5; display: block; height: 1122px; background: black;" />');

			// Append to DOM
			this.$container.append(this.$overlay);

			// Append to DOM
			$("body").append(this.$container);
		}

		$(".lightbox").click(function() { _this.openBox(this); return false; });
		$("#lightbox-overlay, .btn-close-lightbox").click(function() { _this.closeBox(this); return false; });
	},

	/**
	 * Open a lightbox
	 *
	 * @param  {object} id
	 * @return {void}
	 */
	openBox: function(el) {
		var $el = $(el);
		var $popup = $($el.data("popup"));

		if ($popup.length) {
			$popup.fadeIn();
			this.$container.fadeIn(100);
		}
	},

	/**
	 * Close all boxes
	 *
	 * @return {void}
	 */
	closeBox: function() {
		$(".popup").fadeOut(100);
		this.$container.fadeOut();
	},

	/**
	 * Go to step for finding a boat
	 *
	 * @param  {integer} step
	 * @return {void}
	 */
	goBoatStep: function(step) {
		$(".popup-find-boat .step").hide();
		$(".popup-find-boat .step-" + step).show();
	},

	/**
	 * Handle some resize events
	 *
	 * @return {void}
	 */
	resize: function() {
		console.log("Resize");
		this.resizeBoatListTags();
	},

	/**
	 * Add events to some elements
	 */
	addEvents: function() {
		var _this = this;

		// Responsive navigation
		$(".burger-menu").click(function() {
			$("#nav").toggle();
			return false;
		});

		$(".fancy-select").select2();
		$(".btn-find-step-2").click(function() { _this.goBoatStep(2); return false; });
		$(".btn-find-step-3").click(function() { _this.goBoatStep(3); return false; });
	}

};

window.cookieconsent_options = {
	message: "This site uses cookies lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.",
	dismiss: "I agree",
	theme:  false
};

$(function() {
	App.init();
});
